<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ `Добавление документов  [${model.title}]` }}
      </template>
      {{ model }}<br />
      {{ data.data }}<br /> 
      {{ api }}
      <a-form-model
        v-model="data"
        :model="model"
        :errors="errors"
        :config="{ dense: true }"
        @validate="validate($event)"
      />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save :disabled="!loaded" @click="submit()" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    modelDoc: Object,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.modelDoc));
      model.validator = ["req"];
      return [model];
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
        }
      }
    },
  },
  async created() {
    this.loaded = !true;
    if (this.value) {
      await this.updateData(this.id);
      this.loaded = true;
      if (!this.id) {
      }
    }
  },
  methods: {
    async submit() {
      if (await this.validateAll(this.data)) {
        let data = { id: this.id };
        let model = this.modelDoc;
        let field = model.name;
        if (model?.json) {
          field = model?.jsonField || "data";
          console.log("json field", field);
        }
        data[field] = this.data[field];
        console.log("post data", field, this.api, data);
        //await this.post(data, this.api);
        return true;
      }
      return false;
    },
    afterSave(data, status) {
      this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>